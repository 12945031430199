/**
 * @file
 * Styles for sections (Used on basic page and blog
 */

@import "variables";

.page-sections{
  .section{
    display:inline;
    width: 100%;
    clear: both;
    margin-bottom: 20px;

    .section-images{
      margin-bottom: 20px;
      max-width: 100%;
      
      figure{
        position: relative;
        line-height: 0;
        

        img{
         margin-bottom: 0;
        }
        figcaption{
          line-height: 1.1em;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 5px;
          background: rgba(0, 0, 0, .6);
          color: #fff;
          @include border-radius(0 0 12px 12px);
        }
      }
    }
    
    &.vert{
      &.left .section-images{
        float: left;
        margin-right: 20px;
      }
      &.right .section-images{
        float: right;
        margin-left: 20px;
      }
      figure{
        margin-bottom: 10px;
      }

    }
    &.horiz .section-images{
      text-align: center;
      
      figure{
        display:inline;
        margin: 0 10px 10px 0;
      }
      figcaption{

      }
    }
  }
}
